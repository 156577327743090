@import "../variable.scss";


.social-div {
    margin: 20px;
    text-align: center;
    // 移动设备
    @media only screen and (max-width: 768px){
        text-align: center;
    }
    // 移动设备横屏
    @media only screen and (orientation:landscape) and (max-width: 1000px){
        margin: 5px; 
        text-align: center;
        // width: 80px;
        // height: 80px;
    }
}

.social-icon {
    margin: 8px;
    font-size: 2em;
    color: $font-color-light;
    &:hover {
        transition: all 0.3s linear 0s;
        color: $font-color-TOHOH;
    }
}

