@import "../variable.scss";

.about-div {
    margin: 10px;
    text-align: center;
    
    p {
        font-size: 1.2em;
        color: $font-color-light;
        @media only screen and (orientation:landscape) and (max-width: 1000px){
            color: $font-color-light;
        }
    }
    .wechat-div {
        margin: 0 auto;
        width: 120px;
        height: 120px;
        background-image: url("/images/wechat.png");
        overflow: auto;
        background-size: cover;
        @media only screen and (orientation:landscape) and (max-width: 1000px){
            width: 80px;
            height: 80px;
        }
    }
    @media only screen and (max-width: 768px){
        text-align: center;
    }
}

