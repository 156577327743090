// 字体颜色
$font-color-light: #f0f0f0;
$font-color-dark: #3e3e3e;

$font-color-black: #1f1f1f;
$font-color-white: #ffffff;
$font-color-gray: #858585;
$font-color-red: #CB1B45;
$font-color-MIZUASAGI: #66BAB7;
$font-color-ASAGI: #33A6B8;
$font-color-KAMENOZOKI: #A5DEE4;
$font-color-SORA: #58B2DC;
$font-color-MIZU: #81C7D4;
$font-color-SHIRONEZUMI: #BDC0BA;
$font-color-TOHOH: #FFC408;
$font-color-NAMAR: #787878;

// 背景颜色
$body-background-light: #f0f0f0;
$body-background-dark: #3e3e3e;

// 默认字体
$body-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
