@import "../variable.scss";

.footer-div {
    // text-align: center;
    position: absolute;
    bottom: 20px;
    width: 100%;

    a {
        margin: 10px; 
        color: $font-color-NAMAR;
        text-decoration: none;

    }
}