@import "../variable.scss";


.App {
    position: absolute;
    text-align: center;
    background-image: url("/images/background.jpg");
    background-size: cover;
    overflow: auto;
    width: 100%;
    height: 100%;
}


.description-div {
    position: absolute;
    top: calc(75% - 200px);
    left: 10%;
    height: 200px;
    width: 300px;
    @media only screen and (max-width: 768px){
        top: calc(72% - 200px);
        left: calc(50% - 150px);
    }
    @media only screen and (orientation:landscape) and (max-width: 1000px){
        width: 80%;
        top: calc(80% - 200px);
        left: calc(50% - 40%);
    }
}

.background-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

