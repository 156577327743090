@import "../variable.scss";


.navbar-div {
    position: absolute;
    width: 100%;
    top: 20%;
    font-size: 1.5em;
    font-weight:bold;
    a {
        margin: 0 60px 0 60px; 
        color: $font-color-light;
        // color: $font-color-ASAGI;
        text-decoration: none;
        &:hover {
            transition: all 0.3s linear 0s;
            color: $font-color-TOHOH;
            
        }
    }
    @media only screen and (max-width: 768px){
        a {
            margin: 0 20px 0 20px; 
            // color: $font-color-dark;
            text-decoration: none;
        }
    }
}

// a.hover {
//     font-size: 5em;
// }


